.HeaderTitle {
	height: 100%;
	border: none;
	color: black;
	text-decoration: none;
	font-weight: 400;
	line-height: 140%;
	transition: 0.3s ease;
	padding: 30px 0px;
}
